import React from 'react'
import PressLayout from '../../components/pressLayout'
import Share from '../../components/share'
import { Link } from 'gatsby'

export default function OttawaRestaurateursPivot () {
  const shareTitle = 'Some Ottawa restaurateurs have pivoted to more pandemic-resistant businesses'
  const articleLink = 'https://ottawacitizen.com/news/some-restaurateurs-pivot-out-of-the-food-business'

  return (
    <PressLayout>
      <section className='story'>
        <div className='row align-center'>
          <div className='col'>
            <div className="inner">
              <h1>Some Ottawa restaurateurs have pivoted to more pandemic-resistant businesses</h1>

              <header className="article-header">
                <div className="article-details">
                  <span className='news'>News</span>
                  <span>May 10, 2021</span>
                </div>

                <Share shareTitle={shareTitle} />
              </header>

              <div className="article-body">
                <img src="/img/press/pat-nicastro.jpg" alt="Co-owner of Bottega Nicastro, Pat Nicastro, stands in the wine section of his salad restaurant, Lollo, in the Byward Market. PHOTO BY JULIE OLIVER /Postmedia" />
                <div className="snippet">The following preview is from the <a href={articleLink} target="_blank" rel='noreferrer'>Ottawa Citizen</a></div>
                <p>Meanwhile, the emptying of Ottawa’s downtown core cost La Bottega customers. But the grocery business soldiered on and began offering deliveries through the fledgling Ottawa-based business Trexity.</p>
                <p>That service “was a missing key in our business to make it work,” Nicastro says. The grocery store does “tons of deliveries every day through Trexity.” Not only that, Nicastro was so impressed by the service that he became an early seed investor.</p>
                <a href={articleLink} target='_blank' rel='noreferrer'>Read more at the Ottawa Citizen</a>
              </div>

              <footer className="article-footer">
                <div className="article-next">
                  <Link to="/press/ottawa-teams-up-to-boost-local" className='cta-link'>Related Article <i className="material-icons">keyboard_arrow_right</i></Link>
                  <p>Ottawa Board of Trade, BIA coalition team up on discount, delivery programs to boost local businesses</p>
                </div>

                <Share shareTitle={shareTitle} />
              </footer>
            </div>
          </div>
        </div>
      </section>
    </PressLayout>
  )
}
